<template>
  <div class="step-1">
    <div style="color: #333; margin: 15px 0 15px">
      <span style="color: #409EFF">填写规则</span>
      ：同队伍运动员，可签同一张责任书签名，或分开多张责任书
    </div>
    <div style="color: #333; margin-bottom: 15px">
      <span style="color: #409EFF">上传图片规则</span>
      ：仅支持jpg、jpeg、png格式的图片
    </div>
    <!-- <el-image :src="demo1" :preview-src-list="[demo1]"></el-image>
    <el-image :src="demo2" :preview-src-list="[demo2]"></el-image> -->
    <el-upload
      :action="
        $store.state.user.authority == 1
          ? `${rootUrl}/admin/upload/responsibility`
          : `${rootUrl}/api/upload/responsibility`
      "
      list-type="picture-card"
      :data="{ uid: uid, token: $store.state.user.token }"
      :on-preview="handlePictureCardPreview"
      :before-remove="handleRemove"
      :on-error="handleError"
      :on-success="handleSuccess"
      :with-credentials="true"
      :before-upload="beforeUpload"
      class="upload-wrap"
      accept="image/png, image/jpeg"
      :headers="myHeaders"
      :file-list="fileList"
      :drag="true"
    >
      <i class="el-icon-plus" />
      <div class="el-upload__text">将文件拖到此处</div>
      <div class="el-upload__text">或<em>点击上传</em></div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
    <div class="btn-group step-btn-group">
      <!-- <el-button type="primary">
        上一步
      </el-button>-->
      <el-button type="primary" @click="prevStep()">上一步</el-button>
      <el-button
        type="primary"
        @click="nextStep()"
        v-if="$store.state.user.authority === 0"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
// import tinymce from 'tinymce/tinymce'
// import Editor from '@tinymce/tinymce-vue'
import demo1 from "@/assets/demo-responsibility1.jpg";
import demo2 from "@/assets/demo-responsibility2.jpg";
// 引入image-conversion
import * as imageConversion from "image-conversion";
import { getToken, getCookie } from "@/utils/auth";
import {
  getResponsibility,
  getResponsibilityAdmin,
  delResponsibilityAdmin,
  delResponsibility
} from "@/api";
import { BASE_URL } from "@/const";

export default {
  name: "Step1",
  components: {
    // Editor
  },
  props: {},
  data() {
    return {
      demo1,
      demo2,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      list: [],
      rootUrl: BASE_URL
    };
  },
  computed: {
    myHeaders() {
      return {
        "X-AccountType": getToken(),
        "X-Token": getCookie("authority"),
        "X-Author": this.$store.getters.author
      };
    },
    uid() {
      return this.$store.state.user.authority === 1
        ? this.$route.query.id || this.$store.state.user.id
        : this.$store.state.user.id;
    }
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      const request =
        this.$store.state.user.authority === 0
          ? getResponsibility
          : getResponsibilityAdmin;

      request()
        .then(res => {
          let data = res.data.list.length > 0 ? res.data.list.split(",") : [];

          let file = [];
          data.forEach(item => {
            file.push({
              url: item
            });
          });
          this.fileList = file;
          this.list = file;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
    handleRemove(file, fileList) {
      return new Promise((resolve, reject) => {
        const request =
          this.$store.state.user.authority === 0
            ? delResponsibility
            : delResponsibilityAdmin;

        request()
          .then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success"
              });
              this.visible = false;
              this.getList();
              resolve();
            } else {
              this.$message.error(res.data.message || "删除失败");
              reject();
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("删除失败");
            reject();
          });
      });
    },
    handleError(err, file, fileList) {
      this.$message({
        type: "error",
        message: err
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.success("上传成功");
      this.list.push({
        uid: file.uid,
        url: file.url
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    prevStep() {
      this.$store.dispatch("STEP_PREV", {
        router: this.$router,
        route: this.$route
      });
    },
    nextStep() {
      if (this.list.length > 0) {
        this.$store.dispatch("STEP_NEXT", {
          router: this.$router,
          route: this.$route
        });
      } else {
        this.$alert("请上传责任书", "系统提示", {
          confirmButtonText: "确定"
        });
      }
    },
    //图片大小超过1M,长度超过2000就压缩
    beforeUpload(file) {
      // 图片不大于1.5m,宽度不大于2000
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let isLt1M = file.size / 1024 / 1024 > 1; // 判定图片大小是否小于1MB
        // 这里需要计算出图片的长宽
        let img = new Image();
        img.onload = function() {
          file.width = img.width; // 获取到width放在了file属性上
          file.height = img.height; // 获取到height放在了file属性上
          let valid = img.width > 2000; // 图片宽度大于2000
          // console.log(11, file)
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          if (valid || isLt1M) {
            imageConversion
              .compressAccurately(file, {
                size: 280,
                width: 2000
              })
              .then(res => {
                // console.log('res', res) // 压缩到400KB,这里的400就是要压缩的大小,可自定义
                resolve(res);
              });
          } else {
            resolve(file);
          }
        }; // 需要把图片赋值
        img.src = _URL.createObjectURL(file);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-image {
  width: 150px;
  height: 150px;
  border: 1px solid #c0ccda;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}
.upload-wrap {
  border: 1px solid rgb(133, 132, 132);
  border-radius: 5px;
  padding: 20px 20px;
}
.btn-group {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>

<style>
.el-upload-dragger {
  width: 100%;
  height: 100%;
}
</style>
